import { useHistory } from 'react-router';

import { getIsCompletePet, getIsLoggedIn } from '@pumpkincare/shared';

import { useDeleteQuotePet } from './quote-mutations';
import {
  getQuoteId,
  getQuotePets,
  getQuoteTransactionFee,
  useQuote,
} from './quote-query';
import { fetchQuoteWithFeeById } from './quotes-service';
import { getWellnessQuoteId, useWellnessQuote } from './wellness-quote-query';

export default function useGoToCheckout(checkoutPath) {
  const history = useHistory();

  const { data: quoteData } = useQuote();
  const { data: wellnessQuoteData } = useWellnessQuote();
  const { mutate: deleteQuotePet } = useDeleteQuotePet();

  function goToCheckout() {
    const isLoggedIn = getIsLoggedIn();

    const petsData = getQuotePets(quoteData);
    const transactionFee = getQuoteTransactionFee(quoteData);
    const quoteId = getQuoteId(quoteData);
    const wellnessQuoteId = getWellnessQuoteId(wellnessQuoteData);
    const incompletePets = petsData.filter(pet => !getIsCompletePet(pet));

    // TODO: doesn't seem we need to make the request for transaction fee anymore
    return (
      isLoggedIn
        ? Promise.resolve()
        : Promise.resolve(transactionFee || fetchQuoteWithFeeById({ quoteId }))
    ).then(() => {
      incompletePets.forEach(pet => {
        deleteQuotePet({ quoteId, wellnessQuoteId, quotePetId: pet.id });
      });

      history.push(checkoutPath);
    });
  }

  return goToCheckout;
}
